<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    :query="query"
    entity-name="生日关怀"
    hide-action
    show-row-num
    :columns="columns"
    :search-key-type="searchKeyType"
    :requestConfig="{ noTempleFilter: true }"
  >
    <template v-slot:title="{ total }">
      <span>共{{ total }}条记录</span>
      <span class="title-tip">今日志工生日名单</span>
    </template>
    <template v-slot:query="{ form }">
      <a-form-model-item>
        <a-input v-model="form.name" placeholder="输入志工姓名查询" allow-clear></a-input>
      </a-form-model-item>
    </template>
    <template v-slot:button>
      <a-button type="primary" @click="exportData" :loading="exporting">
        {{ exporting ? "正在导出" : "批量导出" }}
      </a-button>
    </template>
  </data-list>
</template>

<script>
import { volunStatusOptions } from "../../../common/hr/volunteer";
import { getQuery, downLoadBlobFile, timestampConvertString, getKeyTitle, getTempleId } from "../../../common/js/tool";

export default {
  name: "BirthdayCare",
  data() {
    return {
      url: "/admin/volunteer/birthday-list",
      searchKeyType: {
        name: 2,
      },
      columns: [
        {
          title: "日期",
          dataIndex: "birthday",
          customRender: (text) => {
            return <span>{timestampConvertString(text, "yyyy-MM-dd") || "-"}</span>;
          },
        },
        { title: "姓名", dataIndex: "name" },
        { title: "身份证号", dataIndex: "id_card" },
        { title: "注册小组", dataIndex: "group_name" },
        {
          title: "注册小组志工状态",
          dataIndex: "register_status",
          customRender: (text) => {
            return <span>{getKeyTitle(volunStatusOptions, text, "value", "label") || "-"}</span>;
          },
        },
      ],
      exporting: false,
    };
  },
  computed: {
    dataList() {
      return this.$refs.dataList;
    },
    query() {
      return getTempleId();
    },
  },
  methods: {
    updateList() {
      this.dataList.updateList();
    },
    exportData() {
      if (this.exporting) return;
      this.exporting = true;
      let url = "/admin/volunteer/birthday-export?"
      url += getTempleId();
      url += getQuery.call(this.dataList, this.searchKeyType);
      this.$axios(url, { noTempleFilter: true, responseType: "blob" })
        .then((res) => {
          downLoadBlobFile(res, "生日名单数据");
        })
        .finally(() => {
          this.exporting = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.title-tip {
  margin-left: 24px;
  cursor: default;
}
</style>
